import Link, { LinkProps } from 'next/link'
import s from './button.module.css'
import cx from 'classnames'

interface IButtonProps {
  children: React.ReactNode
  black?: boolean
  transparent?: boolean
  noBorder?: boolean
  isFavorite?: boolean
  href?: string
  disabled?: boolean
  className?: string
  onClick?: (e?: any) => void // Due to inconsistencies between the onclick types of button and link
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export const Button = ({
  children,
  black,
  disabled = false,
  isFavorite,
  href,
  transparent,
  noBorder,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
  ...props
}: IButtonProps & (React.ComponentProps<'button'> | LinkProps)) => {
  return (
    <>
      {href ? (
        <Link
          {...(props as LinkProps)}
          href={href}
          aria-disabled={disabled}
          tabIndex={disabled ? -1 : undefined}
          className={cx(
            s.button,
            {
              [s.black]: black,
              [s.transparent]: transparent,
              [s.noBorder]: noBorder,
              [s.disabled]: disabled,
            },
            className
          )}
          onClick={onClick}
        >
          {children}
        </Link>
      ) : (
        <button
          {...(props as React.ComponentProps<'button'>)}
          onMouseLeave={onMouseLeave}
          onMouseOver={onMouseEnter}
          disabled={disabled}
          className={cx(
            {
              [s.button]: !isFavorite,
              [s.black]: black,
              [s.favoriteButton]: isFavorite,
              [s.disabled]: disabled,
            },
            className
          )}
          onClick={onClick}
        >
          {children}
        </button>
      )}
    </>
  )
}
