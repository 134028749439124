import s from './section-header.module.css'
import cx from 'classnames'

type Props = {
  children?: React.ReactNode
} & React.HTMLAttributes<HTMLHeadingElement>

export const SectionHeader = (props: Props) => {
  const { children, className, ...rest } = props
  return (
    <h2 className={cx(s.header, className)} {...rest}>
      {children}
    </h2>
  )
}
