import s from './article-tile.module.css'
import { FAVOURITE } from '@/constants'
import { FavouriteIcon } from '@/components/fav-icon'
import { Article } from '@/types/Article'
import { Tile } from '@/v2/components/atoms/tile/tile'
import { ImageLoadingType } from '@/v2/components/atoms/image/image'
import { Favourite } from '@/pages/customer/dashboard/[slug]/dashboard'

type Props = {
  article: Article
  adjustToSlider?: boolean
  imageLoading?: ImageLoadingType
}

export function mapFavouriteItemToArticleTile(fav: Favourite) {
  return {
    id: fav.id,
    title: fav.title,
    path: fav.url,
    hero_image_ref: {
      url: fav.logo,
      alt: fav.title,
    },
  } as Article
}

export const V2ArticleTile = ({
  article,
  adjustToSlider,
  imageLoading,
}: Props) => {
  return (
    <Tile
      imageProps={{
        src: article.hero_image_ref?.url,
        alt: article.hero_image_ref?.alt,
        loading: imageLoading,
      }}
      href={article.path}
      adjustToSlider={adjustToSlider}
    >
      <div className={s.favoriteContainer}>
        <FavouriteIcon itemId={article.id} type={FAVOURITE.ARTICLE} />
      </div>
      <div className={s.contentContainer}>
        <p className={s.articleCardTitle}>{article.title}</p>
      </div>
    </Tile>
  )
}
