import s from './section-container.module.css'
import cx from 'classnames'

type SectionContainerProps = {
  children?: React.ReactNode
  innerProps?: React.HTMLAttributes<HTMLDivElement>
  sliderSection?: boolean
} & React.HTMLAttributes<HTMLDivElement>

export const SectionContainer = ({
  children,
  innerProps,
  ...wrapperProps
}: SectionContainerProps) => {
  const { sliderSection, ...restWrapperProps } = wrapperProps

  return (
    <div
      {...restWrapperProps}
      className={cx(restWrapperProps.className, s.wrapper)}
    >
      <div
        {...innerProps}
        className={cx(innerProps?.className, s.wrapperContent, {
          [s.sliderSection]: sliderSection,
        })}
      >
        {children}
      </div>
    </div>
  )
}
